import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsDiscord } from "react-icons/bs";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { Link } from "react-router-dom";

function ProjectCards(props) {
  const redirectLink = props.isAppeal ? "discord.gg/mountain" : (props.isBlog ? "/banlist" : "/warnlist");

  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {/* Conditionally render either "View Bans" or "View Warns" button or "Appeals" button */}
        {props.isAppeal ? (
          <Button variant="primary" href={props.moreInfo} target="_blank">
            <BsDiscord /> &nbsp;
            {"Appeals"}
          </Button>
        ) : (
          <Link to={redirectLink}>
            <Button variant="primary">
              <HiOutlineDocumentSearch /> &nbsp;
              {props.isBlog ? "View Bans" : "View Warns"}
            </Button>
          </Link>
        )}

        {"\n"}
        {"\n"}

        {/* Render the "DevForum" button only when isBlog is false and isAppeal is false */}
        {!props.isBlog && !props.isAppeal && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"DevForum"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
