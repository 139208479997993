import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Mountain Interactive is a game production studio focused on several platforms, making experiences for the <span className="purple"> thousands. </span> 
             <span className="purple"> </span>
             <br />

            <br />
            <ImPointRight />  Mainly Known for Victor Valley and Perris
            <br />
            <br />
            Apart from running a massive community, our developers, Ash and Leo, have been working on a game called <span className="purple"> San Dimas </span>
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Including Massive Frameworks
            </li>
            <li className="about-activity">
              <ImPointRight /> Full Lua and Rojo backend
            </li>
            <li className="about-activity">
              <ImPointRight /> Along with a Javascript / Firebase Membership System
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Success is not final, failure is not fatal: It is the courage to continue that counts."{" "}
          </p>
          <footer className="blockquote-footer">Winston Churchill</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
