import React, { useEffect, useState } from 'react';
import { Container, Card, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Particle from "../Particle";

const BanList = () => {
  const [warns, setWarns] = useState([]);
  const [userId, setUserId] = useState('');

  const [avatarUrls, setAvatarUrls] = useState([]);
  const [usernames, setUsernames] = useState([]);

  const formatEndDate = (endDate) => {
    const formattedDate = new Date(endDate).toLocaleString();
    const today = new Date();
    const endOfCentury = new Date();
    endOfCentury.setFullYear(today.getFullYear() - 100);
    const endOfNextCentury = new Date();
    endOfNextCentury.setFullYear(today.getFullYear() + 100);
    if (new Date(endDate) > endOfNextCentury) {
      return "Permantantly Banned";
    } else {
      return formattedDate;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await Promise.all(
        warns.slice(0, 10).map((warn) => fetchUserDataById(warn.userid))
      );
      setAvatarUrls(userData.map((data) => data.avatarUrl));
      setUsernames(userData.map((data) => data.username));
    };
  
    fetchUserData();
  }, [warns]);
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('https://simpleadm.in/api/get-warndata', {
        params: {
          userid: userId,
          limit: 10,
        },
      });
      setWarns(response.data);
    };

    fetchData();
  }, [userId]);

  const handleSearch = (e) => {
    e.preventDefault();
    setUserId(e.target.userid.value);
  };

  const fetchUserDataById = async (userId) => {
    try {
      const response = await axios.get(`/api/${userId}`);
      const userData = response.data;
      const username = userData.name;
      const avatarUrl = await fetchAvatarUrl(userId);
      return { username, avatarUrl };
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchAvatarUrl = async (userId) => {
    try {
      const response = await axios.get(`/avatar-api?userIds=${userId}&size=180x180&format=Png&isCircular=true`);
      const data = response.data;
      if (data && data.data && Array.isArray(data.data) && data.data.length > 0) {
        const imageUrl = data.data[0].imageUrl;
        return imageUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleUsernameClick = (userId) => {
    window.open(`https://www.roblox.com/users/${userId}/profile`, '_blank');
  };
  

  return (
    <Container fluid className="p-0" style={{ marginTop: '80px', backgroundColor: 'rgba(255, 255, 255, 0)' }}>
      <Particle />
      <Container fluid className="my-3 text-white">
        <Row className="justify-content-center">
          <Col xs={12} className="text-center">
            <p className="mb-0">View the most recent warnings:</p>
          </Col>
        </Row>
        <Row className="justify-content-center" >
          <Col xs={6}>
            <Form onSubmit={handleSearch}>
              <InputGroup>
                <Form.Control type="text" id="userid" name="userid" placeholder="Enter User ID" className="rounded" />
                <Button type="submit" className="ml-2">Search</Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>
      <Container className="text-white">
      {warns.slice(0, 10).map((warn, index) => (
  <Card key={`${warn.userid}-${index}`} className="my-2" style={{ border: '2px solid purple', backgroundColor: 'rgba(255, 255, 255, .35)'  } }>
        <Card.Body>
          <Row>
            <Col xs={3} className="text-center" >
              {avatarUrls[index] && <img src={avatarUrls[index]} alt="User avatar" className="rounded-circle" />}
            </Col>
            <Col xs={9}>
              <blockquote className="blockquote mb-0" >
              <p style={{ color: "#fffff" }}>User ID | Roblox Username: <span style={{textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleUsernameClick(warn.userid)}>{warn.userid} (@{usernames[index]})</span></p>

                <p style={{ color: "#fffff" }}>Moderator: {warn.adminid}</p>
                <p style={{ color: "#fffff" }}>Reason: {warn.content}</p>
                <p style={{ color: "#fffff" }}>Timestamp: {new Date(warn.timestamp).toLocaleString()}</p>
                </blockquote>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))}
  </Container>
</Container>
);
};

export default BanList;