import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        Mountain Interacitve
        <strong className="purple"> Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          All projects that have been worked upon by Mountain Interacitve
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={true}
              title="SimpleAdmin"
              description="SimpleAdmin is a game-management suite designed to act as a total solution to controlling your game. We also designed SimpleAdmin to fill the holes in pre-existing administration suites. This includes a sleek & responsive interface, customer satisfaction, and frequent community driven updates."
              ghLink="https://github.com/crywink/SimpleAdmin"
              moreInfo="https://discord.gg/mountain"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isSpeical={true}
              isBlog={true}
              title="SimpleAdmin Pro"
              description="SimpleAdmin Pro is our premium plan that gives you ultimate control over your game. We offer a feature rich API and host a custom Discord bot for your convenience that you can use to ban/kick users, make announcements to everybody in-game, shut down your game, and more! If you're looking for a great way to manage your game even on the go, SimpleAdmin is for you!"
              moreInfo="https://discord.gg/mountain"
              specialDemo="https://mountaininteractive.com/resources/simple-admin-pro.6/"
              ghLink="https://github.com/crywink/SimpleAdmin"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
